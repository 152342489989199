import router from './router'
import NProgress from 'nprogress'
import Auth from './utils/authorization'
import store from "./store";
import Layout from '@/layout/Index.vue';

const whiteList = ['/login', '/register']

router.beforeEach((to, from, next) => {
    NProgress.start();
    if (Auth.getToken()) {
        /* has token*/
        if (to.path === '/login' || to.path === '/home') {
            next();
            NProgress.done()
        } else {
            if (to.path === '/') {
                next("/home")
            } else {
                if (sessionStorage.getItem("refreshed") === "0") {
                    sessionStorage.setItem("refreshed", "1");
                    store.dispatch("setRefreshed", true).then(()=> {
                        let userMenuList = store.getters.userInfo.menuList;
                        let routes = [];
                        for (let i = 0; i < userMenuList.length; i++) {
                            let category = userMenuList[i];
                            let route = {
                                name: category.name,
                                path: '',
                                component: Layout
                            };
                            let menuList = category.children;
                            let children = [];
                            for (let j = 0; j < menuList.length; j++) {
                                let child = menuList[j];
                                children.push({
                                    name: child.name,
                                    path: child.path,
                                    component: (resolve) => require([`@/views` + child.path], resolve)
                                })
                            }
                            route.children = children;
                            routes.push(route);
                        }
                        router.addRoutes(routes);
                        next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
                    });
                } else {
                    next()
                }
            }
            NProgress.done()
        }
    } else {
        // 没有token
        if (whiteList.indexOf(to.path) !== -1) {
            // 在免登录白名单，直接进入
            next()
        } else {
            next(`/login?redirect=${to.path}`); // 否则全部重定向到登录页
            NProgress.done()
        }
    }
})
