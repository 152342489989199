import {get} from '@/utils/http'; // 导入http中创建的axios实例
import base from '../base'; // 导入接口域名列表

const IndexMod = {
    // 用户profile
    getUserProfile(params) {
        return get(`${base.host}/admin/v1/index/profile`, params);
    },
}

export default IndexMod;
