/**
 * api接口的统一出口
 */
// 登录模块接口
import LoginMod from '@/api/modules/login';
import IndexMod from '@/api/modules/index';
import ResourceMod from '@/api/modules/system/resource';
import ConfigMod from '@/api/modules/system/config';
import RoleMod from '@/api/modules/system/role';
import DeptMod from '@/api/modules/system/dept';
import DictMod from '@/api/modules/system/dict';
import UserMod from '@/api/modules/system/user';

// 导出接口
export default {
    LoginMod,
    IndexMod,
    ResourceMod,
    ConfigMod,
    RoleMod,
    DeptMod,
    DictMod,
    UserMod,
    // ……
}
