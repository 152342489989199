import router from "../../router";


const state = {
    sidebar :{
        collapse : false,
        activeMenuIndex : '0',
    },
    tagsView : {
        editableTabs: [{
            title: '首页',
            name: '0',
            path: '/home',
            closeable : false
        }],
        activeTabValue : '0'
    },
    refreshed : false
};

const mutations = {
    TOGGLE_SIDEBAR: state => {
        state.sidebar.collapse = !state.sidebar.collapse;
    },
    ADD_TAG_VIEW: (state, view) => {
        state.tagsView.editableTabs.push(view);
    },
    CHANGE_ACTIVE_TAB_VALUE: (state, activeTabValue) => {
        state.tagsView.activeTabValue = activeTabValue;
    },
    CLEAR_APP_STATE: state => {
        state.sidebar = {
            collapse : false,
            activeMenuIndex : '0',
        };
        state.tagsView = {
            editableTabs: [{
                title: '首页',
                name: '0',
                path: '/home',
                closeable : false
            }],
            activeTabValue : '0'
        };
    },
    SET_REFRESHED : (state, refreshed) => {
        state.refreshed = refreshed;
    },
    SET_ACTIVE_MENU_INDEX : (state , index) => {
        state.sidebar.activeMenuIndex = index;
    },
    SET_EDITABLE_TABS : (state, tabs) => {
        state.tagsView.editableTabs = tabs;
    },
    REMOVE_TAB : (state, name) => {
        let tabs = state.tagsView.editableTabs;
        let removeIndex = 0;
        for(let i = 0; i < tabs.length; i++) {
            let tab = tabs[i];
            if (tab.name === name) {
                removeIndex = i;
                break;
            }
        }
        let activeTab = tabs[removeIndex - 1];
        tabs.splice(removeIndex, 1);
        state.tagsView.editableTabs = tabs;
        if (state.tagsView.activeTabValue !== activeTab.name) {
            state.sidebar.activeMenuIndex = activeTab.name;
            state.tagsView.activeTabValue = activeTab.name;
            router.push(activeTab.path);
        }
    }
}

const actions = {
    toggleSideBar({ commit }) {
        commit('TOGGLE_SIDEBAR')
    },
    addTabView({commit}, view) {
        commit("ADD_TAG_VIEW", view);
    },
    changeActiveTabValue({commit}, activeTabValue){
        commit("CHANGE_ACTIVE_TAB_VALUE", activeTabValue);
    },
    clearAppState({commit}) {
        commit("CLEAR_APP_STATE");
    },
    setRefreshed({ commit }, refreshed) {
        commit('SET_REFRESHED', refreshed);
    },
    setActiveMenuIndex({ commit }, index) {
        commit("SET_ACTIVE_MENU_INDEX", index);
    },
    removeTab({ commit }, name) {
        commit("REMOVE_TAB", name);
    }
}

export default {
    state,
    mutations,
    actions
}
