
const state = {
    dynamicRoutes : []
};

const mutations = {
    SET_DYNAMIC_ROUTES: (state, routes) => {
        state.dynamicRoutes = routes;
    }
};

const actions = {
    setDynamicRoutes({commit}, routes) {
        commit("SET_DYNAMIC_ROUTES", routes);
    }
}

export default {
    state,
    mutations,
    actions
}
