import {get, postBody, put, del} from '@/utils/http'; // 导入http中创建的axios实例
import base from '../../base'; // 导入接口域名列表

const DictMod = {
    parentList(params) {
        return get(`${base.host}/admin/v1/sys/dict/parents/` + params);
    },
    page(params) {
        return get(`${base.host}/admin/v1/sys/dict/page`, params);
    },
    add(params) {
        return postBody(`${base.host}/admin/v1/sys/dict/add`, params);
    },
    update(params) {
        return put(`${base.host}/admin/v1/sys/dict/update`, params);
    },
    delete(params) {
        return del(`${base.host}` + "/admin/v1/sys/dict/" + params + "/delete");
    },
    listByCode(code) {
        return get(`${base.host}/admin/v1/sys/dict/list/code/` + code);
    }
}

export default DictMod;
