/**
 * 接口域名的管理
 * localhost   http://localhost:10001
 * server   https://admin.melodyzf.com
 */
const base = {
    host: 'https://admin.melodyzf.com',
    // bd: 'http://xxxxx22222.com/api'
}

export default base;
