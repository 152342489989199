import {post} from '@/utils/http'; // 导入http中创建的axios实例
import base from '../base'; // 导入接口域名列表
const LoginMod = {
    // 登录
    pwdLogin (params) {
        return post(`${base.host}/login`, params);
    },
    // 短信登录
    smsLogin (params) {
        return post(`${base.host}/sms/login`, params);
    },
    // 登出
    logout () {
        return post(`${base.host}/admin/v1/index/logout`);
    }
};

export default LoginMod;
