import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

import Layout from '@/layout/Index.vue'

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Login'),
    },
    {
        path: '',
        name: 'home',
        component: Layout,
        children: [
            {
                name: '首页',
                path: '/home',
                component: () => import('@/views/Home')
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
