<template>

    <el-menu :default-active="activeMenuIndex" class="el-menu-vertical-demo" :collapse="isCollapse" :uniqueOpened="true">
      <div style="height: 60px;background-color: #409EFF;"></div>
        <template v-for="item in menuList">
            <el-submenu  v-if="item.type === 'C'" :key="item.id" :index="item.id">
                <template slot="title">
                    <i :class="item.ico || `el-icon-folder`"></i>
                    <span slot="title">{{item.name}}</span>
                </template>
                <template v-for="child in item.children">
                    <el-menu-item :key="child.id" :index="child.id" :title="child.name" :path="child.path" @click="addTab">
                        <i :class="child.ico || `el-icon-document`"></i>
                        <span slot="title">{{child.name}}</span>
                    </el-menu-item>
                </template>
            </el-submenu>
            <el-menu-item v-else :key="item.id" :index="item.id" :title="item.name" :path="item.path" @click="addTab">
                <i :class="item.ico || `el-icon-document`"></i>
                <span slot="title">{{item.name}}</span>
            </el-menu-item>
        </template>
    </el-menu>
</template>

<script>
    import { mapState } from 'vuex'
    export default {
        name: 'SideBar',
        computed: {
            ...mapState({
                isCollapse: state => state.App.sidebar.collapse,
                activeMenuIndex: state => state.App.sidebar.activeMenuIndex,
                menuList: state => state.UserInfo.data.menuList
            })
        },
        methods : {
            addTab(target) {

                let componentPath = target.$attrs.path;
                if (componentPath.indexOf("http") > -1) {
                    window.open(componentPath);
                    return;
                }

                let editableTabs = this.$store.getters.tagsView.editableTabs;
                let opened = false;

                for(let i = 0; i < editableTabs.length; i++) {
                    if (editableTabs[i].name === target.index + '') {
                        opened = true;
                        break;
                    }
                }
                if (!opened) {
                    this.$store.dispatch("addTabView", {
                        title: target.$attrs.title,
                        name: target.index + '',
                        path: target.$attrs.path,
                        closeable: true
                    });
                }
                if (this.$store.getters.tagsView.activeTabValue !== target.index + '') {
                    this.$store.dispatch("changeActiveTabValue", target.index + '');
                    this.$store.dispatch("setActiveMenuIndex", target.index + '');
                    this.$router.push(componentPath);
                }
            }
        }
    }
</script>
<style type="text/css">
    .el-menu:not(.el-menu--collapse) {
        width: 200px;
        background-color: #FFF;
    }
    .el-menu {
      border: none;
    }
</style>
