<template>
    <el-tabs :value="activeTabValue" type="card" @tab-remove="removeTab" @tab-click="clickTab">
        <el-tab-pane v-for="item in editableTabs" :key="item.name" :label="item.title"
                     :name="item.name" :closable="item.closeable" :path="item.path" class="app-container">
        </el-tab-pane>
        <keep-alive>
            <router-view/>
        </keep-alive>
    </el-tabs>
</template>

<script>
import {mapState} from 'vuex'

export default {
    name: 'TagsView',
    computed: {
        ...mapState({
            editableTabs: state => state.App.tagsView.editableTabs,
            activeTabValue: state => state.App.tagsView.activeTabValue
        })
    },
    methods: {
        removeTab(targetName) {
            this.$store.dispatch("removeTab", targetName);
        },
        clickTab(target) {
            if (target.name !== this.$store.getters.tagsView.activeTabValue) {
                this.$store.dispatch("changeActiveTabValue", target.name);
                this.$store.dispatch("setActiveMenuIndex", target.name);// target.name
                this.$router.push(target.$attrs.path);
            }
        }
    }
}
</script>
<style type="text/css">
.app-container {
    padding: 10px;
}

.el-tabs__content {
    background-color: #efefef;
    height: calc(100vh - 162px);
    padding: 0 10px;
    border-left: 1px solid #ccc;
    overflow: auto;
}

.el-tabs__header {
    margin: 0 !important;
    padding-bottom: 1px;
    border-left: 1px solid #ccc;
}

/** mz-query-content start */
.mz-query-content {
    background-color: #FFF;
    margin: -10px 0 10px;
    border-radius: 5px;
    box-shadow: 1px 1px 5px #AAA;
}

.mz-query-content .el-form-item {
    margin-bottom: 0 !important;
    padding: 10px;
}

.mz-query-content .el-form-item__label {
    width: auto !important;
}
/** mz-query-content end */

/** mz-table-content start */
.mz-table-content {
    height: calc(100vh - 300px);
    background-color: #FFF;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 2px 2px 5px #AAA;
}

.mz-table-content .el-table__body-wrapper {
    height: calc(100vh - 420px);
    overflow-y: auto;
}

.mz-table-content .el-pagination {
    margin: 8px 0 0;
}

/** mz-table-content end */

/** mz-tree start */
.mz-tree-left {
    width: 240px;
    margin: -10px 0 10px;
    height: calc(100vh - 230px);
    background-color: #FFF;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 2px 2px 5px #AAA;
}

.mz-tree-left .el-tree {
    margin-top: 5px;
    height: calc(100% - 40px);
    overflow: auto;
}

.mz-tree-right {
    width: calc(100% - 270px);
    display:inline-block;
    float: right;
}
/** mz-tree end */
</style>
