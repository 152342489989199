import {get, postBody, put, del} from '@/utils/http'; // 导入http中创建的axios实例
import base from '../../base'; // 导入接口域名列表

const ResourceMod = {
    list(params) {
        return get(`${base.host}/admin/v1/sys/resource/list`, params);
    },
    tree(params) {
        return get(`${base.host}/admin/v1/sys/resource/tree`, params);
    },
    add(params) {
        return postBody(`${base.host}/admin/v1/sys/resource/add`, params);
    },
    update(params) {
        return put(`${base.host}/admin/v1/sys/resource/update`, params);
    },
    delete(params) {
        return del(`${base.host}` + "/admin/v1/sys/resource/" + params + "/delete");
    },
}

export default ResourceMod;
