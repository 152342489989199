import Cookies from 'js-cookie'

const tokenKey = "Authorization";
const refreshTokenKey = "refreshToken";

const Auth = {
    getToken() {
        return Cookies.get(tokenKey)
    },
    setToken(key) {
        Cookies.set(tokenKey, key)
    },
    removeToken() {
        Cookies.remove(tokenKey);
    },
    getRefreshToken() {
        return Cookies.get(refreshTokenKey)
    },
    setRefreshToken(key) {
        Cookies.set(refreshTokenKey, key)
    },
    removeRefreshToken() {
        Cookies.remove(refreshTokenKey);
    }
};

export default Auth
