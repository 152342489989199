import IndexMod from "@/api/modules/index.js";
import LoginMod from "@/api/modules/login.js";
import Auth from "@/utils/authorization.js";

const state = {
    data : {}
};

const mutations = {
    SET_PROFILE: (state, profile) => {
        state.data = profile;
    },
    CLEAR_USER_SATE: state => {
        state.info = {};
    }
};

const actions = {
    getUserInfo({ commit }) {
        return new Promise(((resolve, reject) => {
            IndexMod.getUserProfile().then(res =>{
                if (res.data.code === 200) {
                    commit("SET_PROFILE", res.data.data);
                    resolve()
                } else {
                    reject(res.data.msg);
                }
            }).catch(e => {
                reject(e);
            });
        }));
    },
    setRoles({commit}, roles) {
        commit("SET_ROLES", roles);
    },
    logout({ commit }) {
        return new Promise((resolve, reject) => {
            LoginMod.logout().then(res => {
                if(res.data.code === 200) {
                    commit('CLEAR_USER_SATE');
                    commit('CLEAR_APP_STATE');
                    Auth.removeToken();
                    resolve();
                } else {
                    reject(res.data.msg);
                }
           }).catch(e => {
                reject(e);
            });
        });
    }
}

export default {
    state,
    mutations,
    actions
}
