<template>
    <el-container>
        <SideBar></SideBar>
        <el-container>
            <Header></Header>

            <el-main>
                <TagsView></TagsView>
            </el-main>
            <el-footer>
                mz-admin
            </el-footer>
        </el-container>
    </el-container>
</template>

<script>
    import SideBar from './sidebar/SideBar';
    import TagsView from './tagsview/TagsView';
    import Header from './header/Header';

    export default {
        components: {
                SideBar,
                TagsView,
                Header
            }
    }
</script>
<style type="text/css">
    .el-container {
        height: 100%;
    }
    .el-main {
        padding: 0;
    }
    .el-footer {
        border-top: 1px solid #efefef;
        border-left: 1px  solid #ccc;
    }
</style>
