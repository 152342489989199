import {get, postBody, put, del} from '@/utils/http'; // 导入http中创建的axios实例
import base from '../../base'; // 导入接口域名列表

const RoleMod = {
    page(params) {
        return get(`${base.host}/admin/v1/sys/role/page`, params);
    },
    add(params) {
        return postBody(`${base.host}/admin/v1/sys/role/add`, params);
    },
    update(params) {
        return put(`${base.host}/admin/v1/sys/role/update`, params);
    },
    delete(params) {
        return del(`${base.host}` + "/admin/v1/sys/role/" + params + "/delete");
    },
}

export default RoleMod;
