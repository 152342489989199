import Vue from 'vue'
import Vuex from 'vuex'
import App from './modules/app'
import UserInfo from './modules/userInfo'
import Permission from './modules/permission'

import getters from './getters'


Vue.use(Vuex)

const store = new Vuex.Store({
    modules : {
        App,
        UserInfo,
        Permission
    },
    getters
})

export default store
