import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'
import router from './router'
import store from './store'
import './permission'
import VueParticles from 'vue-particles'
import api from './api/index'
import Auth from './utils/authorization'
import checkPermission from './directive/permission'
import './assets/style/base.scss'

Vue.prototype.$api = api;

Vue.prototype.$auth = Auth;
Vue.use(VueParticles);
Vue.use(checkPermission);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

