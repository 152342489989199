<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

    export default {
      created() {
        //在页面加载时读取sessionStorage里的状态信息
        if (sessionStorage.getItem("store")) {
          this.$store.replaceState(Object.assign({}, this.$store.state,JSON.parse(sessionStorage.getItem("store"))))
        }

        //在页面刷新时将vuex里的信息保存到sessionStorage里
        window.addEventListener("beforeunload",()=>{
          sessionStorage.setItem("refreshed", "0");
          sessionStorage.setItem("store", JSON.stringify(this.$store.state));

        })
      }
    }
</script>


<style>
html,body,#app{
    padding: 0; margin: 0; height: 100%;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #AAA;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #EEE;
}
</style>
