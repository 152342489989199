<template>
    <el-header style="border-left: 1px solid #10932F; padding: 0">
        <el-row style="line-height: 60px; background-color: #409EFF;">
            <el-col :span="6" class="btn-fold">
                <i class="el-icon-s-fold" v-show="!isCollapse" @click="handleFold"
                   style="font-size: 28px; cursor: pointer;"></i>
                <i class="el-icon-s-unfold" v-show="isCollapse" @click="handleFold"
                   style="font-size: 28px; cursor: pointer;"></i>
            </el-col>
            <el-col :span="14">
                <div style="height: 60px; line-height: 60px;"></div>
            </el-col>
            <el-col :span="4" class="user-info">
                <el-dropdown trigger="click" style="height: 60px; line-height: 60px;">
                            <span class="el-dropdown-link">{{username}}
                                <i class="el-icon-setting el-icon--right"></i>
                            </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>查看</el-dropdown-item>
                        <el-dropdown-item>新增</el-dropdown-item>
                        <el-dropdown-item divided><span @click="logout">登出</span></el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </el-col>
        </el-row>
    </el-header>
</template>

<script>
    import { mapState } from 'vuex'
    export default {
        name: "Header",
        computed: {
            ...mapState({
                isCollapse: state => state.App.sidebar.collapse,
                username: state => state.UserInfo.data.nickName || '小M'
            })
        },
        methods : {
            handleFold() {
                this.$store.dispatch('toggleSideBar');
            },
            logout() {
                this.$store.dispatch('logout').then(() =>{
                    location.reload();
                })
            }
        }
    }
</script>
<style type="text/css">
    .btn-fold {
        padding: 0 10px;
    }
    .user-info {
        padding: 0 20px;
        text-align: right;
    }
    .el-dropdown {
      color: #FFFF
    }
</style>
